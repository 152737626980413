.transparentButton {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  display: flex;
  justify-content: center;
  align-content: center;
}

.transparentButton:focus {
  outline: none;
}

.buttonText {
  margin-left: 0.5rem;
  font-size: 1.15rem;
  font-weight: 500 !important;
}

.convertSvgToPurpleFill {
  filter: invert(70%) sepia(5%) saturate(648%) hue-rotate(205deg)
    brightness(90%) contrast(81%);
}
