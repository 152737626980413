/* The container must be positioned relative: */
.customSelect {
  position: relative;
  font-family: 'Source Sans Pro', sans-serif, Arial !important;
  /* margin: 10px; */
  width: 11.5rem;
  padding: 0.2rem 0.75rem;
  border: 1px solid #ccc;
  height: 34px;
  color: #686868;
  appearance: none; /* remove default arrow */
  background: url(../../../../assets/icons/triangle.svg) 93% / 15% no-repeat
    #fff;
  background-size: 0.75rem;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.customSelect select {
  display: none; /*hide original SELECT element: */
}

select:focus,
textarea:focus,
input:focus {
  outline: none !important;
}

.select-selected {
  background-color: DodgerBlue;
}

/* Style the arrow inside the select element: */
.select-selected:after {
  position: absolute;
  content: '';
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/* style the items (options), including the selected item: */
.select-items div,
.select-selected {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
}

/* Style items (options): */
.select-items {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/* Hide the items when the select box is closed: */
.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}
