.container {
  display: block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  /* position: absolute; */
  display: none;
  /* opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0; */
}

/* Create a custom checkbox */
.container label {
  cursor: pointer;
  height: 14px;
  width: 14px;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #808495;
  box-shadow: inset 0 0 0 1px #fff;
  margin: 0 0.5rem 0 0;
}

/* On mouse-over, add a grey background color */
.container:hover label ~ .checkmark {
  background-color: #808495;
  box-shadow: inset 0 0 0 1px #fff;
}

/* When the checkbox is checked, add a blue background */
.container label:checked ~ .checkmark {
  background-color: #808495;
}

.container input[type='checkbox']:checked + label {
  background-color: #808495;
}

/* .container input:focus ~ .checkmark {
  outline: none !important;
  border-color: #808495;
  box-shadow: 0 0 4px #808495;
} */
