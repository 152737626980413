.shadow {
  background-color: #ffffffdf;
  width: 100%;
  height: 100%;
}

.fullTextBoxWrapper {
  width: 70%;
  margin: auto;
  padding: 2rem;
}

.halfTextBoxWrapper {
  margin: 2rem 4rem;
  width: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-height: 80vh;
}

.threeFourthsTextBoxWrapper {
  margin: 2rem 10rem;
  width: 100%;
  background-color: transparent;
  max-height: 80vh;
}

.customColumnsTextBoxWrapper {
  margin: 2rem 6rem;
  width: 100%;
  background-color: transparent;
}

.quoteTextBoxWrapper {
  margin: 2rem 1rem;
  padding: 3rem;
  background-color: #ffffffdf;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-height: 80vh;
}

.textBox {
  color: #4d4f5c;
  background-color: transparent;
  max-height: 80vh;
  overflow-y: scroll;
  margin-bottom: 2rem;
  padding-right: 1rem;
}

.textBox::-webkit-scrollbar {
  width: 6px;
}
.textBox::-webkit-scrollbar * {
  background: transparent;
}
.textBox::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1) !important;
  border-radius: 12px;
}
