.sessionCard {
  width: 100%;
  padding: 1rem;
  margin: 1rem 0;
  border: 1px lightgray solid;
  border-radius: 3px;
}

.sessionCardImg {
  width: 12rem;
  height: 7rem;
  border-radius: 3px;
  border: 1px lightgray solid;
  object-fit: cover;
}

.sessionCardTitle {
  font-weight: bold;
}

.sessionCardText {
  display: inline-block;
  margin: 0 2rem;
}
