.cell {
  border: none !important;
  padding: 1.5rem 2.5rem !important;
}

.row:nth-child(odd) {
  background-color: #f6f6fa !important;
}

.row:nth-child(even) {
  background-color: #fbfbfd !important;
}
