* {
  font-family: 'Source Sans Pro', sans-serif, Arial !important;
}

body {
  background-color: #f0f0f7 !important;
}

h1 {
  font-size: 35px !important;
}

p {
  font-size: 16px;
}

button,
ul,
li,
a,
input,
/* span, */
p {
  font-weight: 300 !important;
}

span {
  background-color: transparent !important;
}

html,
body,
#app,
#app > div {
  height: 100%;
}

a:hover {
  text-decoration: none !important;
}

/* Color Palette */
.color-palette {
  color: #43425d #65648f #4d4f5c #7b7b7b #929292 #f0f0f7;
}

/* Bootstrap Override Begin */
.btn-primary {
  background-color: #43425d !important;
  border-color: #43425d !important;
}

.btn-signup {
  background-color: #838296;
  color: white;
}

.btn-contact-form {
  background-color: #66657c !important;
}

.btn-patient-signup {
  background-color: #40628f;
  color: white;
}

.btn-outline-patient-signup {
  color: #40628f !important;
  border-color: #40628f !important;
}

.btn-outline-patient-signup:hover {
  color: white !important;
  background-color: #40628f !important;
}

.btn-outline-primary {
  color: #43425d !important;
  border-color: #43425d !important;
}

.btn-outline-primary:hover {
  color: white !important;
  background-color: #43425d !important;
}

.g-text-mute {
  color: #777 !important;
}

/* Bootstrap Override End */

/* Global Class Begin */
.g-input-box {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid lightgrey !important;
  margin: 2rem 0 !important;
  padding: 0.25rem !important;
  display: block !important;
  width: 100% !important;
  background-color: #fff !important;
}

.g-input-box:focus {
  outline: none !important;
}

.g-button-override {
  background: none;
  border: none;
  padding: 0.5rem 1rem;
}

.g-button-override:focus {
  outline: none !important;
}

.g-button-override:hover {
  background: #f5f5f5 !important;
}

.g-text-color {
  color: #43425d !important;
}

.g-link {
  color: #43425d !important;
  cursor: pointer !important;
}

.g-link:hover {
  opacity: 0.7 !important;
}

.g-link-special {
  color: #43425d !important;
  cursor: pointer !important;
}

.g-link-special:hover {
  text-decoration: underline;
}

.g-bg {
  background-color: #43425d;
}

.g-opacity {
  background-color: #ffffffc7;
}

.g-header {
  font-weight: bold !important;
  color: #43425d;
  text-transform: uppercase;
  letter-spacing: 0.5rem;
}

.bm-item-list > li:last-child {
  margin-bottom: 6rem !important;
}

/* Global Class End */
