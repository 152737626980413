.imageStyle {
  width: 7rem;
  height: 4rem;
  margin-right: 1rem;
  display: inline-block;
  vertical-align: top;
  border: 1px gray solid;
  border-radius: 3px;
  object-fit: cover;
}

.textWrapper {
  display: inline-block;
}

.nameStyle {
  display: block;
  margin-bottom: 0.25rem;
}

.spanStyle {
  display: block;
  font-size: 0.8rem;
  margin: 0;
  line-height: 0.9rem;
  color: #777;
  text-transform: capitalize;
}

.markInactiveStyle {
  display: block;
  font-size: 0.8rem;
  margin: 0;
  line-height: 0.9rem;
  text-transform: capitalize;
  padding: 0;
  border: 0;
  text-align: left;
}
