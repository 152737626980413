.imageStyle {
  height: 7rem;
  width: 12rem;
  border: 1px gray solid;
  border-radius: 4px;
  object-fit: cover;
}

.titleStyle {
  margin: 0.5rem 0;
}
