.fileDropWrapper {
  background: #f6f6f6;
  text-align: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.fileDrop {
  outline: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.fileDrop:hover {
  cursor: pointer;
}

.logoStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.backgroundStyle {
  width: 100%;
  height: 20rem;
}

.customDropzone {
  margin: 2rem 0;
  height: 70%;
  width: 100%;
  display: flex;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
}

.customDropzone:hover {
  cursor: pointer;
  background: #f5f5f5;
}

.customDropzone:focus {
  outline: none;
}

.dropzoneContent {
  margin: auto;
}
