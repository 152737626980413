.invertVector {
  -webkit-filter: invert(100%);

  /* safari 6.0 - 9.0 */
  filter: invert(100%);
}

.icon {
  width: 1rem;
}
