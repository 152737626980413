.navbar {
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);
}

.navbarNav > li {
  padding-left: 1rem;
}

.navbarBrand {
  background-color: #3c3b54;
}

.navbarBrand:hover > * {
  color: #fdfdfd96;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdownContent {
  display: none;
  position: absolute;
  background-color: white;
  border: solid 1px lightgray;
  min-width: 160px;
  border-radius: 4px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdownContent a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdownContent a:hover {
  background-color: #f5f5f5;
  color: black;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdownContent {
  display: block;
}
