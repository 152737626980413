.navLink {
  display: block;
  width: 100%;
  color: #fdfdfd;
  padding: 1rem;
  background-color: #43425d;
}

.navLink:hover {
  background-color: #43425ddc;
  color: #fdfdfd96;
}

.navLinkActive {
  display: block !important;
  width: 100%;
  color: #fdfdfd;
  padding: 1rem;
  background-color: #43425d !important;
  box-shadow: inset 4px 0 0 -2px #007bff;
}

.navList {
  text-decoration: none;
  list-style: none;
  margin-top: 3.6rem;
  padding: 0;
  display: block;
  width: 100%;
}

.navItem {
  display: block;
}

.navLinkIcon {
  width: 1rem;
  margin-bottom: 4px;
  margin-right: 0.5rem;
}

.navbar {
  background-color: #43425d !important;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .navList {
    text-decoration: none;
    list-style: none;
    margin-top: 5rem;
    padding: 0;
    display: block;
    width: 100%;
  }
}
