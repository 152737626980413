.wrapper {
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  padding: 2rem;
  height: 100%;
}

.imageStyle {
  max-width: 18rem;
  align-self: center;
}

.descriptionStyle {
  color: white;
  margin: 3rem 1rem;
  text-align: left;
  line-height: 23px;
  font-size: 16px;
}
