.picture {
  width: 15rem;
  height: 15rem;
  border: 1px solid black;
  margin: 1rem 13rem 4rem;
}

.description {
  margin: 2rem 0;
}

.backgroundLabel input[type='file'] {
  position: absolute;
  top: -1000px;
}

.backgroundLabel:hover {
  opacity: 0.9;
}

.backgroundUpload:focus {
  outline: none !important;
}
