* {
  font-family: 'Source Sans Pro', sans-serif, Arial !important;
}

body {
  background-color: #f0f0f7 !important;
}

h1 {
  font-size: 35px !important;
}

p {
  font-size: 16px;
}

button,
ul,
li,
a,
input,
/* span, */
p {
  font-weight: 300 !important;
}

span {
  background-color: transparent !important;
}

html,
body,
#app,
#app > div {
  height: 100%;
}

a:hover {
  text-decoration: none !important;
}

/* Color Palette */
.color-palette {
  color: #43425d #65648f #4d4f5c #7b7b7b #929292 #f0f0f7;
}

/* Bootstrap Override Begin */
.btn-primary {
  background-color: #43425d !important;
  border-color: #43425d !important;
}

.btn-signup {
  background-color: #838296;
  color: white;
}

.btn-contact-form {
  background-color: #66657c !important;
}

.btn-patient-signup {
  background-color: #40628f;
  color: white;
}

.btn-outline-patient-signup {
  color: #40628f !important;
  border-color: #40628f !important;
}

.btn-outline-patient-signup:hover {
  color: white !important;
  background-color: #40628f !important;
}

.btn-outline-primary {
  color: #43425d !important;
  border-color: #43425d !important;
}

.btn-outline-primary:hover {
  color: white !important;
  background-color: #43425d !important;
}

.g-text-mute {
  color: #777 !important;
}

/* Bootstrap Override End */

/* Global Class Begin */
.g-input-box {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid lightgrey !important;
  margin: 2rem 0 !important;
  padding: 0.25rem !important;
  display: block !important;
  width: 100% !important;
  background-color: #fff !important;
}

.g-input-box:focus {
  outline: none !important;
}

.g-button-override {
  background: none;
  border: none;
  padding: 0.5rem 1rem;
}

.g-button-override:focus {
  outline: none !important;
}

.g-button-override:hover {
  background: #f5f5f5 !important;
}

.g-text-color {
  color: #43425d !important;
}

.g-link {
  color: #43425d !important;
  cursor: pointer !important;
}

.g-link:hover {
  opacity: 0.7 !important;
}

.g-link-special {
  color: #43425d !important;
  cursor: pointer !important;
}

.g-link-special:hover {
  text-decoration: underline;
}

.g-bg {
  background-color: #43425d;
}

.g-opacity {
  background-color: #ffffffc7;
}

.g-header {
  font-weight: bold !important;
  color: #43425d;
  text-transform: uppercase;
  letter-spacing: 0.5rem;
}

.bm-item-list > li:last-child {
  margin-bottom: 6rem !important;
}

/* Global Class End */

.LeftContent_wrapper__2UQOh {
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  padding: 2rem;
  height: 100%;
}

.LeftContent_imageStyle__1ESr6 {
  max-width: 18rem;
  align-self: center;
}

.LeftContent_descriptionStyle__GjUZS {
  color: white;
  margin: 3rem 1rem;
  text-align: left;
  line-height: 23px;
  font-size: 16px;
}

.Navbar_navbar__3mHwJ {
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);
}

.Navbar_navbarNav__j0Tdq > li {
  padding-left: 1rem;
}

.Navbar_navbarBrand__K4O35 {
  background-color: #3c3b54;
}

.Navbar_navbarBrand__K4O35:hover > * {
  color: #fdfdfd96;
}

/* The container <div> - needed to position the dropdown content */
.Navbar_dropdown__1xhZC {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.Navbar_dropdownContent__cNKIc {
  display: none;
  position: absolute;
  background-color: white;
  border: solid 1px lightgray;
  min-width: 160px;
  border-radius: 4px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.Navbar_dropdownContent__cNKIc a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.Navbar_dropdownContent__cNKIc a:hover {
  background-color: #f5f5f5;
  color: black;
}

/* Show the dropdown menu on hover */
.Navbar_dropdown__1xhZC:hover .Navbar_dropdownContent__cNKIc {
  display: block;
}

.DashboardLayout_wrapper__2CPRN {
  margin-top: 1.3rem;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .DashboardLayout_wrapper__2CPRN {
    margin-top: 2.9rem;
  }
}

.DashboardLayout_showSidebarButton__2ZJjN {
  background-color: #c4c6c8b3 !important;
}

.DashboardLayout_navLink__1a3sM {
  display: block;
  width: 100%;
  color: #fdfdfd;
  padding: 1rem;
  background-color: #43425d;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.DashboardLayout_navLink__1a3sM:hover {
  background-color: #43425d;
  color: #fdfdfd96;
}

.DashboardLayout_navLink__1a3sM:focus {
  outline: none;
}

.DashboardLayout_navLinkActive__qBb3D {
  display: block !important;
  width: 100%;
  color: #fdfdfd;
  padding: 1rem;
  background-color: #3c3b54 !important;
  box-shadow: inset 4px 0 0 -2px #007bff;
}

.DashboardLayout_navListTitle__3DsMy {
  margin: 1rem;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.DashboardLayout_navList__2Js19 {
  text-decoration: none;
  list-style: none;
  margin: 0;
  padding: 0;
  display: block;
  width: 100%;
}

.DashboardLayout_navItem__3syC5 {
  display: block;

  /* padding-right: 1rem; */
}

.DashboardLayout_navLinkIcon__3jH2_ {
  width: 1rem;
  margin-bottom: 4px;
  margin-right: 0.5rem;
}

.DashboardLayout_contentTypeIcon__20vH0 {
  width: 0.8rem;
  filter: invert(100%);

  /* safari 6.0 - 9.0 */
  -webkit-filter: invert(100%);
  margin-right: 4px;
  margin-bottom: 2px;
}

.DashboardLayout_navbar__pemfL {
  background-color: #43425d !important;

  /* z-index: 9; */
}

.DashboardLayout_navbarWrapper__1--EI {
  position: fixed;
  height: 100%;
  overflow-y: auto;
}

.DashboardLayout_burgerToggle__2U-Qj {
  width: 3rem;
  height: 2rem;
  display: flex;
  align-items: center;
  align-content: center;
  border: 1px solid #29303a;
  background: #f0f0f7;
  z-index: 1001;
}

.DashboardLayout_btnBurger__3CGMY {
  padding: 0 0.25rem;
}

.DashboardLayout_invertVectorColor__3Bpxe {
  -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
  filter: invert(100%);
}

.DashboardLayout_burgerButton__39wrk:hover {
  -webkit-animation-name: DashboardLayout_floating__18ZVo;
          animation-name: DashboardLayout_floating__18ZVo;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
}

@-webkit-keyframes DashboardLayout_floating__18ZVo {
  from {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  35% {
    -webkit-transform: translate(0, -6px);
            transform: translate(0, -6px);
  }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}

@keyframes DashboardLayout_floating__18ZVo {
  from {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  35% {
    -webkit-transform: translate(0, -6px);
            transform: translate(0, -6px);
  }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}

.ContentSidebar_navLink__1PmWw {
  display: block;
  width: 100%;
  color: #fdfdfd;
  padding: 1rem;
  background-color: #43425d;
}

.ContentSidebar_navLink__1PmWw:hover {
  background-color: #43425ddc;
  color: #fdfdfd96;
}

.ContentSidebar_navLinkActive__2-Nc5 {
  display: block !important;
  width: 100%;
  color: #fdfdfd;
  padding: 1rem;
  background-color: #43425d !important;
  box-shadow: inset 4px 0 0 -2px #007bff;
}

.ContentSidebar_navList__3Rn4p {
  text-decoration: none;
  list-style: none;
  margin-top: 3.6rem;
  padding: 0;
  display: block;
  width: 100%;
}

.ContentSidebar_navItem__3mxej {
  display: block;
}

.ContentSidebar_navLinkIcon__LWYCK {
  width: 1rem;
  margin-bottom: 4px;
  margin-right: 0.5rem;
}

.ContentSidebar_navbar__2mg5n {
  background-color: #43425d !important;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .ContentSidebar_navList__3Rn4p {
    text-decoration: none;
    list-style: none;
    margin-top: 5rem;
    padding: 0;
    display: block;
    width: 100%;
  }
}

.SessionCard_sessionCard__1vgHs {
  width: 100%;
  padding: 1rem;
  margin: 1rem 0;
  border: 1px lightgray solid;
  border-radius: 3px;
}

.SessionCard_sessionCardImg__3-npW {
  width: 12rem;
  height: 7rem;
  border-radius: 3px;
  border: 1px lightgray solid;
  object-fit: cover;
}

.SessionCard_sessionCardTitle__3CDTc {
  font-weight: bold;
}

.SessionCard_sessionCardText__1B_17 {
  display: inline-block;
  margin: 0 2rem;
}

.ContinueButton_transparentButton__xaPNk {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  display: flex;
  justify-content: center;
  align-content: center;
}

.ContinueButton_transparentButton__xaPNk:focus {
  outline: none;
}

.ContinueButton_buttonText__1SlIo {
  margin-left: 0.5rem;
  font-size: 1.15rem;
  font-weight: 500 !important;
}

.ContinueButton_convertSvgToPurpleFill__WRDRP {
  -webkit-filter: invert(70%) sepia(5%) saturate(648%) hue-rotate(205deg)
    brightness(90%) contrast(81%);
          filter: invert(70%) sepia(5%) saturate(648%) hue-rotate(205deg)
    brightness(90%) contrast(81%);
}

.Checkbox_container__XPxwu {
  display: block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.Checkbox_container__XPxwu input {
  /* position: absolute; */
  display: none;
  /* opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0; */
}

/* Create a custom checkbox */
.Checkbox_container__XPxwu label {
  cursor: pointer;
  height: 14px;
  width: 14px;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #808495;
  box-shadow: inset 0 0 0 1px #fff;
  margin: 0 0.5rem 0 0;
}

/* On mouse-over, add a grey background color */
.Checkbox_container__XPxwu:hover label ~ .Checkbox_checkmark__mmBfI {
  background-color: #808495;
  box-shadow: inset 0 0 0 1px #fff;
}

/* When the checkbox is checked, add a blue background */
.Checkbox_container__XPxwu label:checked ~ .Checkbox_checkmark__mmBfI {
  background-color: #808495;
}

.Checkbox_container__XPxwu input[type='checkbox']:checked + label {
  background-color: #808495;
}

/* .container input:focus ~ .checkmark {
  outline: none !important;
  border-color: #808495;
  box-shadow: 0 0 4px #808495;
} */

.options_optionContainer__2eQEq {
  padding: 14px 10px;
}

/* The container must be positioned relative: */
.Create_customSelect__2-Y2Y {
  position: relative;
  font-family: 'Source Sans Pro', sans-serif, Arial !important;
  margin: 10px;
  width: 11.5rem;
  padding: 0.2rem 0.75rem;
  border: 1px solid #ccc;
  height: 34px;
  color: #686868;
  appearance: none; /* remove default arrow */
  background: url(/static/media/triangle.f105d308.svg) 93% / 15% no-repeat #fff;
  background-size: 0.75rem;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.Create_customSelect__2-Y2Y select {
  display: none; /* hide original SELECT element: */
}

.Create_customButton__1VcMt {
  font-family: 'Source Sans Pro', sans-serif, Arial !important;
  border: 1px solid #ccc;
  color: #686868;
  border-radius: 20%;
  width: 2rem;
  margin: 0 0.25rem;
}

.Create_customButton__1VcMt:focus {
  outline: none !important;
}

select:focus,
textarea:focus,
input:focus {
  outline: none !important;
}

.Create_select-selected__sCR7Q {
  background-color: DodgerBlue;
}

/* Style the arrow inside the select element: */
.Create_select-selected__sCR7Q::after {
  position: absolute;
  content: '';
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/* Point the arrow upwards when the select box is open (active): */
.Create_select-selected__sCR7Q.Create_select-arrow-active__2FvCW::after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/* style the items (options), including the selected item: */
.Create_select-items__1kPF6 div,
.Create_select-selected__sCR7Q {
  color: #fff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
}

/* Style items (options): */
.Create_select-items__1kPF6 {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/* Hide the items when the select box is closed: */
.Create_select-hide__1azb6 {
  display: none;
}

.Create_select-items__1kPF6 div:hover,
.Create_same-as-selected__32tDm {
  background-color: rgba(0, 0, 0, 0.1);
}

.Create_editor__1ciuB {
  padding: 0 0.5rem 0.5rem 1rem;
  max-height: 25rem;
}


.Create_preview__2GPZr p {
  max-width: 75%;
  text-align: justify;
  margin: auto;
  margin-bottom: 1rem;
}
.FileDrop_fileDropWrapper__2qmWv {
  background: #f6f6f6;
  text-align: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.FileDrop_fileDrop__1QrJR {
  outline: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.FileDrop_fileDrop__1QrJR:hover {
  cursor: pointer;
}

.FileDrop_logoStyle__uViw0 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.FileDrop_backgroundStyle__29N_V {
  width: 100%;
  height: 20rem;
}

/* The container must be positioned relative: */
.Edit_customSelect__1YN_5 {
  position: relative;
  font-family: 'Source Sans Pro', sans-serif, Arial !important;
  margin: 10px;
  width: 11.5rem;
  padding: 0.2rem 0.75rem;
  border: 1px solid #ccc;
  height: 34px;
  color: #686868;
  appearance: none; /* remove default arrow */
  background: url(/static/media/triangle.f105d308.svg) 93% / 15% no-repeat #fff;
  background-size: 0.75rem;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.Edit_customSelect__1YN_5 select {
  display: none; /* hide original SELECT element: */
}

.Edit_customButton__3CoN1 {
  font-family: 'Source Sans Pro', sans-serif, Arial !important;
  border: 1px solid #ccc;
  color: #686868;
  border-radius: 20%;
  width: 2rem;
  margin: 0 0.25rem;
}

.Edit_customButton__3CoN1:focus {
  outline: none !important;
}

select:focus,
textarea:focus,
input:focus {
  outline: none !important;
}

.Edit_select-selected__1ATBl {
  background-color: DodgerBlue;
}

/* Style the arrow inside the select element: */
.Edit_select-selected__1ATBl::after {
  position: absolute;
  content: '';
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/* Point the arrow upwards when the select box is open (active): */
.Edit_select-selected__1ATBl.Edit_select-arrow-active__eahMC::after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/* style the items (options), including the selected item: */
.Edit_select-items__P6eqh div,
.Edit_select-selected__1ATBl {
  color: #fff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
}

/* Style items (options): */
.Edit_select-items__P6eqh {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/* Hide the items when the select box is closed: */
.Edit_select-hide__1eQzs {
  display: none;
}

.Edit_select-items__P6eqh div:hover,
.Edit_same-as-selected__1rT6U {
  background-color: rgba(0, 0, 0, 0.1);
}

.Edit_preview__16QS1 p {
  max-width: 75%;
  text-align: justify;
  margin: auto;
  margin-bottom: 1rem;
}
body {
  background-color: white !important;
}
bold {
  font-weight: bold;
}

underline {
  text-decoration: underline;
}

input[type="radio"] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  border-radius: 50%;
  -webkit-transform: translateY(-0.1em);
          transform: translateY(-0.1em);
  display: inline-grid;
  place-content: center;
}

input[type="radio"]::before {
  --size: 0.35em;
  content: "";
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transition: 120ms -webkit-transform ease-in-out;
  transition: 120ms -webkit-transform ease-in-out;
  transition: 120ms transform ease-in-out;
  transition: 120ms transform ease-in-out, 120ms -webkit-transform ease-in-out;
  box-shadow: inset 1em 1em var(--white);
}

input[type="radio"]:checked::before {
  -webkit-transform: scale(1);
          transform: scale(1);
}

input[type="radio"]:checked {
  border: 0.3em solid var(--dark);
  background-color: var(--dark);
}

label span {
  position: absolute;
  -webkit-transform: translate(-2.7em, 1.25em);
          transform: translate(-2.7em, 1.25em);
  width: calc(1.15em * 4);
}

.Assessment_scaleBanner__19vF3 {
  max-width: 1024px;
  width: 1024px;
  position: relative;
}

.Assessment_scaleBanner__19vF3::before {
  content: '';
  width: 210vw;
  position: absolute;
  background-color: rgb(108 117 125 / 25%);
  height: 100%;
  left: -70vw;
}

.Assessment_scaleBanner__19vF3 span {
  width: 85.33px;
  max-width: 85.33px;
}

.Assessment_minMaxWidth__2_7Ye {
  min-width: 512px;
  max-width: 512px;
}

.Assessment_backfill__1L_61:before {
  content: '';
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: white;
  z-index: -1;
  top: -30vh;
  bottom: 0;
  left: -12.5vw;
  right: 0;
}

.Assessment_backfill__1L_61 {
  position: relative;
  overflow: visible;
}

button.Assessment_continueBtn__1JClv:not(.Assessment_disabled__1caU7) {
  color: hsl(242 17% 60% / 1) !important;
  background-color: hsl(242 17% 31% / 1) !important;
}
.Program_logo__biJKz {
  width: 12rem;
  height: 12rem;
  top: 4.5rem;
  left: 10rem;
  position: absolute;
  object-fit: cover;
  background: white;
  border: 1px solid black;
}

.Program_background__1UXUz {
  z-index: -1;
  position: relative;
  width: 100vw;
  height: 22rem;
  object-fit: cover;
}

.Program_programDescription__1WL4M {
  margin: 3rem 2rem 2rem 2rem;
  align-self: flex-start;
}

.Program_sessionCard__1OEhv {
  width: 100%;
  padding: 1rem;
  margin: 1rem 0;
  border: 1px lightgray solid;
  border-radius: 3px;
}

.Program_sessionCardImg__1O-tx {
  max-width: 12rem;
  max-height: 7rem;
  border-radius: 3px;
  border: 1px lightgray solid;
}

.Program_sessionCardTitle__2IWRp {
  font-weight: bold;
}

.Program_sessionCardText__1PC-k {
  display: inline-block;
  margin: 0 2rem;
}

.Content_shadow__3-tuP {
  background-color: #ffffffdf;
  width: 100%;
  height: 100%;
}

.Content_fullTextBoxWrapper__1SAvf {
  width: 70%;
  margin: auto;
  padding: 2rem;
}

.Content_halfTextBoxWrapper__MYCk4 {
  margin: 2rem 4rem;
  width: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-height: 80vh;
}

.Content_threeFourthsTextBoxWrapper__3-d8z {
  margin: 2rem 10rem;
  width: 100%;
  background-color: transparent;
  max-height: 80vh;
}

.Content_customColumnsTextBoxWrapper__1fSbz {
  margin: 2rem 6rem;
  width: 100%;
  background-color: transparent;
}

.Content_quoteTextBoxWrapper__tRmCr {
  margin: 2rem 1rem;
  padding: 3rem;
  background-color: #ffffffdf;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-height: 80vh;
}

.Content_textBox__2rrC5 {
  color: #4d4f5c;
  background-color: transparent;
  max-height: 80vh;
  overflow-y: scroll;
  margin-bottom: 2rem;
  padding-right: 1rem;
}

.Content_textBox__2rrC5::-webkit-scrollbar {
  width: 6px;
}
.Content_textBox__2rrC5::-webkit-scrollbar * {
  background: transparent;
}
.Content_textBox__2rrC5::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1) !important;
  border-radius: 12px;
}

.CreateProgram_picture__1d9Qq {
  width: 15rem;
  height: 15rem;
  border: 1px solid black;
  margin: 1rem 13rem 4rem;
}

.CreateProgram_description__p-gAK {
  margin: 2rem 0;
}

.CreateProgram_backgroundLabel__1-B55 input[type='file'] {
  position: absolute;
  top: -1000px;
}

.CreateProgram_backgroundLabel__1-B55:hover {
  opacity: 0.9;
}

.CreateProgram_backgroundUpload__2BQhm:focus {
  outline: none !important;
}

.LogoFileDrop_fileDropWrapper__1o5f5 {
  width: 15rem;
  height: 15rem;
  background: #f6f6f6;
  text-align: center;
  border: 1px gray solid;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.LogoFileDrop_logoStyle__jiB22 {
  width: 15rem;
  height: 15rem;
  border: 1px gray solid;
  object-fit: cover;
}

.LogoFileDrop_fileDrop__1IvZ- {
  outline: none;
  padding: 2rem;
}

.LogoFileDrop_fileDrop__1IvZ-:hover {
  cursor: pointer;
}

.ProgramForm_programForm__2mVLe {
  color: #43425d;
  font-weight: 600;
}

.ProgramForm_title__1vS-b {
  display: block;
  border: 1px solid grey;
  width: 25rem;
}

.ProgramForm_title__1vS-b:focus {
  outline: none;
}

.ProgramForm_description__pBqPp {
  display: block;
  width: 100%;
  height: 8rem;
  border: 1px solid grey;
  padding: 0.25rem;
  outline: none;
  resize: none;
}

.ProgramForm_description__pBqPp:focus {
  outline: none;
}

.ProgramForm_inputWrapper__3mfjp {
  margin: 3rem 0;
}

.BackgroundFileDrop_fileDropWrapper__3052M {
  width: 100%;
  height: 20rem;
  background: #f6f6f6;
  text-align: center;
  border: 1px gray solid;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  object-fit: cover;
}

.BackgroundFileDrop_fileDrop__1TC2r {
  outline: none;
  width: 100%;
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
  overflow: hidden;
}

.BackgroundFileDrop_fileDrop__1TC2r:hover {
  cursor: pointer;
}

.BackgroundFileDrop_backgroundStyle__2F81K {
  width: 100%;
  height: 20rem;
}

.SessionForm_programForm__PbFpv {
  color: #43425d;
  font-weight: 600;
}

.SessionForm_title__2LN1I {
  display: block;
  border: 1px solid grey;
  width: 25rem;
}

.SessionForm_title__2LN1I:focus {
  outline: none;
}

.SessionForm_description__2eDIB {
  display: block;
  width: 100%;
  height: 8rem;
  border: 1px solid grey;
  padding: 0.25rem;
  outline: none;
  resize: none;
}

.SessionForm_description__2eDIB:focus {
  outline: none;
}

.SessionForm_inputWrapper__1YFId {
  margin: 3rem 0;
}

.ProgramCard_imageStyle__3gHiP {
  width: 7rem;
  height: 4rem;
  margin-right: 1rem;
  display: inline-block;
  vertical-align: top;
  border: 1px gray solid;
  border-radius: 3px;
  object-fit: cover;
}

.ProgramCard_textWrapper__3Ed_V {
  display: inline-block;
}

.ProgramCard_nameStyle__1nrgB {
  display: block;
  margin-bottom: 0.25rem;
}

.ProgramCard_spanStyle__1TdMq {
  display: block;
  font-size: 0.8rem;
  margin: 0;
  line-height: 0.9rem;
  color: #777;
  text-transform: capitalize;
}

.ProgramCard_markInactiveStyle__348kP {
  display: block;
  font-size: 0.8rem;
  margin: 0;
  line-height: 0.9rem;
  text-transform: capitalize;
  padding: 0;
  border: 0;
  text-align: left;
}

.SessionCard_imageStyle__1916t {
  height: 7rem;
  width: 12rem;
  border: 1px gray solid;
  border-radius: 4px;
  object-fit: cover;
}

.SessionCard_titleStyle__3K8Vp {
  margin: 0.5rem 0;
}

.ContentCard_imageStyle__29UYd {
  height: 7rem;
  width: 12rem;
  border: 1px gray solid;
  border-radius: 4px;
  object-fit: cover;
}

.ContentCard_titleStyle__1S5ag {
  margin: 0.5rem 0;
}

.SessionCard_imageStyle__3ba8v {
  width: 7rem;
  height: 4rem;
  margin-right: 1rem;
  display: inline-block;
  vertical-align: top;
  border: 1px gray solid;
  border-radius: 3px;
  object-fit: cover;
}

.SessionCard_textWrapper__3Q4sb {
  display: inline-block;
}

.SessionCard_nameStyle__3gY6N {
  display: block;
  margin-bottom: 0.25rem;
}

.SessionCard_spanStyle__26OoK {
  display: block;
  font-size: 0.8rem;
  margin: 0;
  line-height: 0.9rem;
  color: #777;
  text-transform: capitalize;
}

.SessionCard_markInactiveStyle__3yHd3 {
  display: block;
  font-size: 0.8rem;
  margin: 0;
  line-height: 0.9rem;
  text-transform: capitalize;
  padding: 0;
  border: 0;
  text-align: left;
}

.Dashboard_invertVector__1s3Kc {
  -webkit-filter: invert(100%);

  /* safari 6.0 - 9.0 */
  filter: invert(100%);
}

.Dashboard_icon__2OsF3 {
  width: 1rem;
}

.Carousel_scrollbarHide__MMj-Z {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}
.Carousel_scrollbarHide__MMj-Z::-webkit-scrollbar {
  display: none !important;
  width: 0 !important;
}

/* The container must be positioned relative: */
.SelectionModal_customSelect__3fNz6 {
  position: relative;
  font-family: 'Source Sans Pro', sans-serif, Arial !important;
  /* margin: 10px; */
  width: 11.5rem;
  padding: 0.2rem 0.75rem;
  border: 1px solid #ccc;
  height: 34px;
  color: #686868;
  appearance: none; /* remove default arrow */
  background: url(/static/media/triangle.f105d308.svg) 93% / 15% no-repeat
    #fff;
  background-size: 0.75rem;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.SelectionModal_customSelect__3fNz6 select {
  display: none; /*hide original SELECT element: */
}

select:focus,
textarea:focus,
input:focus {
  outline: none !important;
}

.SelectionModal_select-selected__1La3I {
  background-color: DodgerBlue;
}

/* Style the arrow inside the select element: */
.SelectionModal_select-selected__1La3I:after {
  position: absolute;
  content: '';
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/* Point the arrow upwards when the select box is open (active): */
.SelectionModal_select-selected__1La3I.SelectionModal_select-arrow-active__2Cmla:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/* style the items (options), including the selected item: */
.SelectionModal_select-items__3vQbZ div,
.SelectionModal_select-selected__1La3I {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
}

/* Style items (options): */
.SelectionModal_select-items__3vQbZ {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/* Hide the items when the select box is closed: */
.SelectionModal_select-hide__3_0sg {
  display: none;
}

.SelectionModal_select-items__3vQbZ div:hover,
.SelectionModal_same-as-selected__1K2tR {
  background-color: rgba(0, 0, 0, 0.1);
}

.MediaUpload_fileDropWrapper__2t4SR {
  background: #f6f6f6;
  text-align: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.MediaUpload_fileDrop__1F7b1 {
  outline: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.MediaUpload_fileDrop__1F7b1:hover {
  cursor: pointer;
}

.MediaUpload_logoStyle__2KPef {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.MediaUpload_backgroundStyle__2hWEE {
  width: 100%;
  height: 20rem;
}

.MediaUpload_customDropzone__Amt-f {
  margin: 2rem 0;
  height: 70%;
  width: 100%;
  display: flex;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
}

.MediaUpload_customDropzone__Amt-f:hover {
  cursor: pointer;
  background: #f5f5f5;
}

.MediaUpload_customDropzone__Amt-f:focus {
  outline: none;
}

.MediaUpload_dropzoneContent__Czkcu {
  margin: auto;
}

.UserDetails_cell__OXZxa {
  border: none !important;
  padding: 1.5rem 2.5rem !important;
}

.UserDetails_row__2pruf:nth-child(odd) {
  background-color: #f6f6fa !important;
}

.UserDetails_row__2pruf:nth-child(even) {
  background-color: #fbfbfd !important;
}

.UsersList_cell__2tKe7 {
  border: none !important;
}

.UsersList_row__1_o1L:nth-child(odd) {
  background-color: #f6f6fa;
}

.UsersList_fontBold__3kEqv {
  font-weight: 500 !important;
}

