.fileDropWrapper {
  width: 15rem;
  height: 15rem;
  background: #f6f6f6;
  text-align: center;
  border: 1px gray solid;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.logoStyle {
  width: 15rem;
  height: 15rem;
  border: 1px gray solid;
  object-fit: cover;
}

.fileDrop {
  outline: none;
  padding: 2rem;
}

.fileDrop:hover {
  cursor: pointer;
}
