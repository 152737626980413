.fileDropWrapper {
  width: 100%;
  height: 20rem;
  background: #f6f6f6;
  text-align: center;
  border: 1px gray solid;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  object-fit: cover;
}

.fileDrop {
  outline: none;
  width: 100%;
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
  overflow: hidden;
}

.fileDrop:hover {
  cursor: pointer;
}

.backgroundStyle {
  width: 100%;
  height: 20rem;
}
