.wrapper {
  margin-top: 1.3rem;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .wrapper {
    margin-top: 2.9rem;
  }
}

.showSidebarButton {
  background-color: #c4c6c8b3 !important;
}

.navLink {
  display: block;
  width: 100%;
  color: #fdfdfd;
  padding: 1rem;
  background-color: #43425d;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.navLink:hover {
  background-color: #43425d;
  color: #fdfdfd96;
}

.navLink:focus {
  outline: none;
}

.navLinkActive {
  display: block !important;
  width: 100%;
  color: #fdfdfd;
  padding: 1rem;
  background-color: #3c3b54 !important;
  box-shadow: inset 4px 0 0 -2px #007bff;
}

.navListTitle {
  margin: 1rem;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.navList {
  text-decoration: none;
  list-style: none;
  margin: 0;
  padding: 0;
  display: block;
  width: 100%;
}

.navItem {
  display: block;

  /* padding-right: 1rem; */
}

.navLinkIcon {
  width: 1rem;
  margin-bottom: 4px;
  margin-right: 0.5rem;
}

.contentTypeIcon {
  width: 0.8rem;
  filter: invert(100%);

  /* safari 6.0 - 9.0 */
  -webkit-filter: invert(100%);
  margin-right: 4px;
  margin-bottom: 2px;
}

.navbar {
  background-color: #43425d !important;

  /* z-index: 9; */
}

.navbarWrapper {
  position: fixed;
  height: 100%;
  overflow-y: auto;
}

.burgerToggle {
  width: 3rem;
  height: 2rem;
  display: flex;
  align-items: center;
  align-content: center;
  border: 1px solid #29303a;
  background: #f0f0f7;
  z-index: 1001;
}

.btnBurger {
  padding: 0 0.25rem;
}

.invertVectorColor {
  -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
  filter: invert(100%);
}

.burgerButton:hover {
  animation-name: floating;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating {
  from {
    transform: translate(0, 0);
  }
  35% {
    transform: translate(0, -6px);
  }
  to {
    transform: translate(0, 0);
  }
}
