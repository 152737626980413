.programForm {
  color: #43425d;
  font-weight: 600;
}

.title {
  display: block;
  border: 1px solid grey;
  width: 25rem;
}

.title:focus {
  outline: none;
}

.description {
  display: block;
  width: 100%;
  height: 8rem;
  border: 1px solid grey;
  padding: 0.25rem;
  outline: none;
  resize: none;
}

.description:focus {
  outline: none;
}

.inputWrapper {
  margin: 3rem 0;
}
