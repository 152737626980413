.fileDropWrapper {
  background: #f6f6f6;
  text-align: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.fileDrop {
  outline: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.fileDrop:hover {
  cursor: pointer;
}

.logoStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.backgroundStyle {
  width: 100%;
  height: 20rem;
}
