body {
  background-color: white !important;
}
bold {
  font-weight: bold;
}

underline {
  text-decoration: underline;
}

input[type="radio"] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  border-radius: 50%;
  transform: translateY(-0.1em);
  display: inline-grid;
  place-content: center;
}

input[type="radio"]::before {
  --size: 0.35em;
  content: "";
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--white);
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

input[type="radio"]:checked {
  border: 0.3em solid var(--dark);
  background-color: var(--dark);
}

label span {
  position: absolute;
  transform: translate(-2.7em, 1.25em);
  width: calc(1.15em * 4);
}

.scaleBanner {
  max-width: 1024px;
  width: 1024px;
  position: relative;
}

.scaleBanner::before {
  content: '';
  width: 210vw;
  position: absolute;
  background-color: rgb(108 117 125 / 25%);
  height: 100%;
  left: -70vw;
}

.scaleBanner span {
  width: 85.33px;
  max-width: 85.33px;
}

.minMaxWidth {
  min-width: 512px;
  max-width: 512px;
}

.backfill:before {
  content: '';
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: white;
  z-index: -1;
  top: -30vh;
  bottom: 0;
  left: -12.5vw;
  right: 0;
}

.backfill {
  position: relative;
  overflow: visible;
}

button.continueBtn:not(.disabled) {
  color: hsl(242 17% 60% / 1) !important;
  background-color: hsl(242 17% 31% / 1) !important;
}