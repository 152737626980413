.cell {
  border: none !important;
}

.row:nth-child(odd) {
  background-color: #f6f6fa;
}

.fontBold {
  font-weight: 500 !important;
}
