.logo {
  width: 12rem;
  height: 12rem;
  top: 4.5rem;
  left: 10rem;
  position: absolute;
  object-fit: cover;
  background: white;
  border: 1px solid black;
}

.background {
  z-index: -1;
  position: relative;
  width: 100vw;
  height: 22rem;
  object-fit: cover;
}

.programDescription {
  margin: 3rem 2rem 2rem 2rem;
  align-self: flex-start;
}

.sessionCard {
  width: 100%;
  padding: 1rem;
  margin: 1rem 0;
  border: 1px lightgray solid;
  border-radius: 3px;
}

.sessionCardImg {
  max-width: 12rem;
  max-height: 7rem;
  border-radius: 3px;
  border: 1px lightgray solid;
}

.sessionCardTitle {
  font-weight: bold;
}

.sessionCardText {
  display: inline-block;
  margin: 0 2rem;
}
